import React, { useState } from 'react';
import './styles/listing.css';
import CardImage from '../assets/svgs/Blog_card.png'
import all from "./data/finetunes.json" ; 
import Header from '../Landing/header';
import Footer from '../Landing/footer';

const Listing = () => {

  const [seeAll, setSeeAll] = useState(true);
  const [finetunes, setFinetunes]= useState([...all])
  // const [taskType, setTaskType] = useState(''); 
  const [taskType, setTaskType] = useState(''); 

  const handleChange = (x) => {
    setTaskType(x)
    let filtered = all.filter((i)=> i.finetune.task === x)
    setFinetunes([...filtered])
  }


  return (
    <> 
    <Header title={'Discover Fine-tunes'} subtitle={'Fine-tune GPT-3 without writing a single line of code!'} />
    <div className='blog_page'>
      <div className="container">
        {/* <div className='main-header'>
          <div className='header-wrapper justify-content-center'>
            <div className='header-buttons text-center'>
              <h4 className='titel'>Discover No Code Fine-tunes</h4>
              <p className='description'>Get inspired to fine-tune GPT-3 without writing single lin of code.</p>
            </div>
          </div>
        </div> */}
        <div className='blog_container'>
          <div className="blog-in-progress">
            <div className="col-4 mt-3 mb-3">
              <div className="side_bar">

                <ul className='menu_items mt-3'>
                  <li className="item">
                    <span className='title'>Task Type</span>
                    <div className={`menu_link ${taskType==='text-classification'? 'active' : ''}`} onClick={()=>{ handleChange('text-classification')}} >Text Classification </div>
                    <span className={`menu_link ${taskType==='text-generation'? 'active' : ''}`}  onClick={()=>{ handleChange('text-generation')}} >Text Generation</span>
                  </li>
                  <hr />
                  {/* <li className="item">
                    <span className='title'>Industry</span>
                    <a href={''} className="menu_link">Retail and E-commerce</a>
                    <a href={''} className='menu_link'>Financial Services and Banking</a>
                    <a href={''} className='menu_link'>Healthcare and Life Sciences</a>
                    <a href={''} className='menu_link'>Transportation and Logistics</a>
                    <a href={''} className='menu_link'>Education and Research</a>
                  </li> */}
                </ul>
              </div>
            </div>
            <div className="col-12 mt-3 mb-3" style={{ width: 'calc(100% - 304px)' }}>
              <div className="right_section">
                {
                  finetunes.map((item) => (
                    <div className="card align-items-center text-center">
                      <img className="" style={{width:"80%", padding:"20px"}} src={item.img} alt="CardImage" />
                      <div className="card_conent">
                        <div className="title"> { item.friendly_name } </div>
                          <div className="description mt-1">
                            <a href={`/fine-tune/${item.url_name}`} style={{color:'#0071BD', textDecoration:'underline'}}>
                               Build it Yourself {'>>'}  
                            </a>  
                          </div>
                      </div>
                    </div>
                  ))
                }
              </div>
            </div>
          </div>
        </div>
      </div>
        
    </div>
    <Footer />
    </>
  );
};

export default Listing;
