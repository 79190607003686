import React, { useEffect, useState } from "react";
import Editicon from '../assets/svgs/Editicon.svg'



const GenerationCard = ({ cardData, moreData  }) => {

    console.log(cardData.data.cards)
    const [edit , setEdit ] = useState(false) ;
    /*
    const [inputValue, setInputValue] = useState(cardData.completion);

    const handleInputChange = (event) => {
        setInputValue(event.target.value);
    };

    const handleEditView = () =>{
        setEdit(true)
    }

    const handleCancel = ()=> {
        setEdit(false)
    }

    const handleSave = async () => {
        
        if (inputValue === cardData.firstSelectedCompletion.completion) {
          setEdit(false);
          return;
        }
        
        cardData.firstSelectedCompletion.completion = inputValue;
        console.log('card ++ edited ')
        setEdit(false);
      };      

    useEffect(() => {
        setEdit(false); // Reset edit state when card changes
        setInputValue(cardData.firstSelectedCompletion.completion)
      }, [cardData]);
    */

    return <div className="gridsCards questionAnswerCards">
               {
                  cardData.data.cards.map((item, i) =>  i <= moreData ? <article className="card-one-classification">
                        <div className="card-body">
                            <div className="">
                                <span style={{fontSize:'12px',  margin: '0' }}> <strong> { item.prompt } </strong></span>
                            </div>    
                            <div style={{fontSize:'12px'}}>
                            {item.completions[0].completion.length > 150 ? 
                            `${item.completions[0].completion.slice(0, 150)}...` : 
                            item.completions[0].completion}
                            </div>
                        </div>
                    
                    </article>: ''
                  )
               }

    </div>
}


export default GenerationCard;

