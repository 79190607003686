import image1 from './Images/section1 image1.png'
// import image1 from './Images/section1 image1.svg'
import image2 from './Images/section1 image2.png'

const LandingSec1 = () => {
    return (
        <div className="section1_main_div container">
            <div className="section1_header">
                <div className="amty_div"></div>
                <p>What is Texti.ai ?</p>
            </div>
            <div className="section1_paragraf">
                {/* <p>Texti.ai is a no-code platform to customize generative AI models. It offers a complete, collaborative AI platform for customizing Open Source and proprietary LLMs.</p> */}
                <p>
                    Texti.ai is a no-code platform that allows for no-code customization of foundational AI models through fine-tuning and in-context learning. <br/>
                    Above all, it is agnostic to specific AI models, offering broad compatibility and flexibility.
                
                </p>
            </div>
            <div className="row">
                <div className="col-md-6 col-12">
                    <p className='Fine-tuning-p m-0'>Fine-Tuning</p>
                    <p className='Refin-models-p m-0'>
                    Fine-tuning is a powerful technique used to improve the accuracy of AI models. By training a pre-existing model on a smaller, specific dataset for a new task, it enhances its accuracy for that particular task. 
                    <a style={{color: '#0d6efd'}} href="https://docs.texti.ai/fine-tuning" target='_blank' rel="noreferrer"> Read more.</a>
                    </p>
                    <img src={image1} className='Fine-tuning-img mt-4' alt="" />
                </div>
                <div className="col-md-6 col-12">
                    <p className='Fine-tuning-p m-0'>In-Context Learning</p>
                    <p className='Refin-models-p m-0'>
                    In-Context Learning with Retrieval Augmented Generation (RAG) enhances large language models (LLMs) by integrating external information. 
                    AI Co-Pilots are built using this powerful technique.
                    <a style={{color: '#0d6efd'}} href="https://docs.texti.ai/in-context-learning" target='_blank' rel="noreferrer"> Read more.</a>
                    </p>
                    <img src={image2} className='Fine-tuning-img mt-4' alt="" />
                </div>
            </div>
        </div>
    )
}

export default LandingSec1