import React, { useState } from 'react';
import './agent.css'; // Make sure the CSS file is named AgentLandingPage.css
import Header from '../Landing/header';
import Footer from '../Landing/footer';
import MediaQuery from "react-responsive";
import Modal from 'react-modal';

import ExternalIcon from "../assets/svgs/externalLink.png"



function AgentLandingPage() {

    const [modalIsOpen, setModalIsOpen] = useState(false);

  function openModal() {
    setModalIsOpen(true);
  }

  function closeModal() {
    setModalIsOpen(false);
  }


  return (
    <div className='NewLandingPage agent-background' >
        <div className="bannerSection" >
                    {/* <Header /> */}
                    <div className="agent-header">
                        <h1 style={{ fontSize:'50px', fontWeight: '450' , marginTop: '80px'}}>Automate Any Process with Texti's AI Agents</h1>
                        <p className="mt-3" >
                        Starting at only $1499, our personalized approach to automation involves creating custom AI Agents, designed to either build upon your data science team's AI models or start entirely from scratch.
                        
                        </p>
                        <div className="agent-header-buttons mt-5">
                            <button class="button-primary"> <a href="https://calendly.com/mukund-texti/30min" target='_blank'> Let's Talk </a></button>
                            <button class="button-secondary" onClick={openModal} >Watch a demo</button>
                        </div>
                    </div>

                    {

                        modalIsOpen && 
                        <Modal
                        isOpen={modalIsOpen}
                        onRequestClose={closeModal}
                        contentLabel="Demo Video"
                        className="modalClass"
                        animationType="fade"
                        transparent={true}
                        overlayClassName="darkOverlay" 
                        >
                        <div className="contentContainer">
                            
                            <div className="row mt-4 justify-content-center text-center">
                                <div className="col-md-12">
                                <iframe 
                                    src="https://player.vimeo.com/video/910104884?h=d6c5e358da&quality=1080p" 
                                    style={{width: '80vw', height: '80vh', borderRadius: '5px'}}
                                    frameBorder="0" 
                                    allow="autoplay; fullscreen; picture-in-picture" 
                                    title="audienceScoreDes"
                                    className="iframeVideoVimeo"
                            />

                                </div>
                            </div>
                            <div className="text-center mb-3 mt-3">
                            <button className="btn btn-primary" onClick={closeModal} > Close </button>
                        </div>

                        </div>
                    </Modal>
                    
                    } 

                    <div className="agent-chart-placeholder" style={{ fontSize:'50px', fontWeight: '450' , marginTop: '45px'}}>
                            <MediaQuery maxDeviceWidth={599}>
                                <iframe 
                                    src="https://player.vimeo.com/video/910116602?h=1144963e48&api=1&background=1&mute=0&quality=1080p" 
                                    style={{width : '90vw', height : '50vh' }}
                                    frameBorder="0" 
                                    allow="fullscreen; picture-in-picture" 
                                    title="audienceScoreDes"

                                /> 
                            </MediaQuery>

                            <MediaQuery minDeviceWidth={600} maxDeviceWidth={1223}>
                                <iframe 
                                    src="https://player.vimeo.com/video/910116602?h=1144963e48&api=1&background=1&mute=0&quality=1080p" 
                                    style={{width : '80vw', height : '60vh', borderRadius: '5px'}}
                                    frameBorder="0" 
                                    allow="fullscreen; picture-in-picture" 
                                    title="audienceScoreDes"
                                    className="iframeVideoVimeo"
                                /> 
                            </MediaQuery>

                            <MediaQuery minDeviceWidth={1224}>
                                <iframe 
                                    src="https://player.vimeo.com/video/910116602?h=1144963e48&api=1&background=1&mute=0&quality=1080p" 
                                    style={{width : '74.5vw', height : '74.2vh', borderRadius: '5px'}}
                                    frameBorder="0" 
                                    allow="fullscreen; picture-in-picture" 
                                    title="audienceScoreDes"
                                    className="iframeVideoVimeo"
                                /> 
                            </MediaQuery>
                    </div>              
               
                </div>

                <div className="use-cases-section">
                    <h2 className="mb-5">Use Cases</h2>
                    <div className="use-cases-container">
                        <div className="use-case">
                            <h4> Real-Time Feedback-to-Action AI Agent </h4>
                            <p className="mt-4">
                                John, an eCommerce Manager, is seeking to enhance his business processes through the use of generative AI models. Specifically, he aims to automate the feedback loop, ensuring that any negative customer feedback received on his webshop is addressed within a minute. 
                            </p>
                            <p className='p-1'>

                                <a href="https://vimeo.com/910106171/7c44613583?share=copy" target='_blank' className="btn btn-primary" style={{ marginRight: '10px' }}> Video </a>
                                <a href="https://docs.texti.ai/ai-agent/real-time-feedback-to-action-ai-agent" target='_blank' className="ml-2"> Read More  <img src={ExternalIcon} alt="" style={{ height:'14px' , width:'14px'}} /> </a>
                            
                            </p>
                            {/* <a style={{color: 'blue'}} href='https://docs.texti.ai/ai-agent/real-time-feedback-to-action-ai-agent' target="_blank"> Read More </a> */}
                        </div>
                        {/* Additional use cases here */}
                    </div>

                </div>
            <Footer />
    </div>
  );
}

export default AgentLandingPage;