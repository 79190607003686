import React, { useEffect, useState } from "react";
// import { getCurrentUser } from "../../services/userService";
import './classification.css'
// import Profiles from '../../asset/img/profiles.png'
// import { editCard } from "../../../services/cardService";

// const LandingPageCard = ({ card, card : initialCard, info }) => {
const LandingPageCard = ({ cardData, moreData }) => {
    
    const [selected, setSelected] = useState('');
    const [selectedname, setSelectedname] = useState(undefined);
    const [completion, setCompletion] = useState('');
    const [card, setCard] = useState();
    const [edit, setEdit] = useState(false);

    const handleCompletionSelect = (completionnew,i) => {
        setSelected(completionnew._id)
        setSelectedname(i.users)
        setEdit(true);
    };
    // console.log("initialCard", initialCard);

    const handleCancel = ()=> {
        setEdit(false)
    }

    const handleSave = async () => {
        // if (
        //     card.completions.some(
        //         (completion) =>
        //             completion.completion === selected &&
        //             completion.users?.includes(currentUser)
        //     )
        // ) {
        //     setEdit(false);
        //     return; // Skip making any backend calls or updating the card
        // }

        // const updatedCompletions = card.completions.map((completion) => {
        //     if (completion.users) {
        //       // Remove currentUser from other completions' users arrays
        //       completion.users = completion.users.filter((user) => user !== currentUser);
        //     }
        //     if (completion.completion === selected) {
        //       // If the completion matches the selected value, add the currentUser to the users array
        //       completion.users = completion.users ? [...completion.users, currentUser] : [currentUser];
        //     }
        //     return completion;
        //   });        

        // const updatedCard = { ...card, completions: updatedCompletions };
        // // await editCard(card._id, card.task, updatedCompletions);

        // setCard(updatedCard);
        // setEdit(false);
        // setCompletion(selected);
    };

    // useEffect(() => {
    //     setEdit(false); // Reset edit state when card changes
    //     setCard(initialCard);

    //     const originalCompletion = initialCard.completions.find(
    //         (completion) =>
    //         completion.users && completion.users.includes(currentUser)
    //     );
    //     const completionValue = originalCompletion ? originalCompletion.completion : '';
    //     setCompletion(completionValue);
    //     //setSelected(completionValue)
    // }, [initialCard, currentUser]);
  
    return (
      <div className="gridsCards questionAnswerCards">
        {
          cardData.cards.map((item, i) =>  i <= moreData ? <article className="card-one-classification">
              <div className="card-body">
                <div className="question">
                  <span >{item.prompt}</span>
                </div>
                <div className="community-btn-groups btn-groups">
                  {
                    item?.completions.map((i, key) => (
                      <div className="btn-group" key={key}>
                        <button
                          style={{ position: 'relative'}}
                          className={`btn ${
                            i.users?.length > 0
                              ? 'completion-user-selected'
                              : ''
                          }`}
                          onClick={() => handleCompletionSelect(item,i)}
                        >
                          <span> {i.completion} </span>
                        </button>
                      </div>
                    ))
                    }
                </div>
              </div>
                {((item._id == selected) && !(selectedname && selectedname.length > 0) && edit)  && (
                  <div className="text-right edit_btn">
    
                    <button className="save-edit-card-button me-2" onClick={handleSave} > Save </button> 
                    <button className="cancel-edit-card-button" onClick={handleCancel} > Cancel </button> 
    
                  </div>
                )}
            </article> : ''
            
         )
        }
      </div>
    );
  };

export default LandingPageCard;
