import typicalImage from './Images/Typical workflow.svg'
import IncontextImage from './Images/In context Image.svg'

const LandingSec3 = () => {
    return (
        <>
        <div className="section3_prent sec3-1 container">
            <div className="section3_main">
                <div></div>
                <p className='m-0'>Fine-Tuning</p>
            </div>

            <div className="section3_body_part row mt-5">
                <div className="col-lg-6 mb-lg-0 mb-5 col-12 Title">
                    <p>Typical workflow</p>
                    <span>No collaboration, constant coding, limited data transparency, and slow evaluations.</span>
                    <img className="mt-1" src={typicalImage} alt="" width={"70%"} height={"auto"}/>
                </div>
                <div className="col-lg-6 col-12">
                    <div className="Title">
                        <p>No Code Fine-tuning with Texti</p>
                        <span>Zero lines of code required, collaborative approach, and easy evaluation.</span>
                    </div>
                    <div className="steps mt-5">
                        <div className="stepItem">
                            <span className="circle">
                                <svg xmlns="http://www.w3.org/2000/svg" width="33" height="34" viewBox="0 0 33 34" fill="none">
                                    <circle cx="16.5" cy="16.5" r="16.5" transform="matrix(1 0 0 -1 0 33.3662)" fill="#30AC63"/>
                                    <path d="M25.5001 11.3662L13.2501 23.6162L8 18.3662" stroke="white" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                            </span>
                            <span className="stepTitle">Upload Data</span>
                        </div>
                        <div className="stepItem">
                            <span className="circle">
                                <svg xmlns="http://www.w3.org/2000/svg" width="33" height="34" viewBox="0 0 33 34" fill="none">
                                    <circle cx="16.5" cy="16.5" r="16.5" transform="matrix(1 0 0 -1 0 33.3662)" fill="#30AC63"/>
                                    <path d="M25.5001 11.3662L13.2501 23.6162L8 18.3662" stroke="white" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                            </span>
                            <span className="stepTitle">Select Task</span>
                        </div>
                        <div className="stepItem">
                            <span className="circle">
                                <svg xmlns="http://www.w3.org/2000/svg" width="33" height="34" viewBox="0 0 33 34" fill="none">
                                    <circle cx="16.5" cy="16.5" r="16.5" transform="matrix(1 0 0 -1 0 33.3662)" fill="#30AC63"/>
                                    <path d="M25.5001 11.3662L13.2501 23.6162L8 18.3662" stroke="white" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                            </span>
                            <span className="stepTitle">Model Selection</span>
                        </div>
                        <div className="stepItem">
                            <span className="circle">
                                <svg xmlns="http://www.w3.org/2000/svg" width="33" height="34" viewBox="0 0 33 34" fill="none">
                                    <circle cx="16.5" cy="16.5" r="16.5" transform="matrix(1 0 0 -1 0 33.3662)" fill="#30AC63"/>
                                    <path d="M25.5001 11.3662L13.2501 23.6162L8 18.3662" stroke="white" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                            </span>
                            <span className="stepTitle">One-Click Fine-Tuning <br /> <span>(or select hyperparameters)</span></span>
                        </div>
                        <div className="stepItem">
                            <span className="circle">
                                <svg xmlns="http://www.w3.org/2000/svg" width="33" height="34" viewBox="0 0 33 34" fill="none">
                                    <circle cx="16.5" cy="16.5" r="16.5" transform="matrix(1 0 0 -1 0 33.3662)" fill="#30AC63"/>
                                    <path d="M25.5001 11.3662L13.2501 23.6162L8 18.3662" stroke="white" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                            </span>
                            <span className="stepTitle">Evaluate</span>
                        </div>
                    </div>
                        {/* <div className='check_video_btn'>
                            <button type='button' >Check Video</button>
                        </div> */}
                </div>
            </div>
        </div>
        <div className="section3_prent sec3-2 container" >
            <div className="section3_main">
                <div></div>
                <p className='m-0'>In-Context Learning</p>
            </div>

            <div className="section3_body_part row mt-5">
                <div className="col-lg-6 col-12 mb-lg-0 mb-5 Title">
                    <p>Typical workflow</p>
                    <span>Data engineering required and lack of collaboration.</span>
                    <img src={IncontextImage} alt=""  width={"85%"} height={"auto"}/>
                </div>
                <div className="col-lg-6 col-12">
                    <div className="Title ">
                        <p>No Code In-Context Learning with Texti.ai</p>
                        <span>Embedding selection, automated workflows, and evaluation support.</span>
                    </div>
                    <div className="steps mt-5" >
                        <div className="stepItem align-items-center text-center" >
                            <span className="circle">
                                <svg xmlns="http://www.w3.org/2000/svg" width="33" height="34" viewBox="0 0 33 34" fill="none">
                                    <circle cx="16.5" cy="16.5" r="16.5" transform="matrix(1 0 0 -1 0 33.3662)" fill="#30AC63"/>
                                    <path d="M25.5001 11.3662L13.2501 23.6162L8 18.3662" stroke="white" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                            </span>
                            <span className="stepTitle">Upload Unstructured Data</span>
                        </div>
                        <div className="stepItem">
                            <span className="circle">
                                <svg xmlns="http://www.w3.org/2000/svg" width="33" height="34" viewBox="0 0 33 34" fill="none">
                                    <circle cx="16.5" cy="16.5" r="16.5" transform="matrix(1 0 0 -1 0 33.3662)" fill="#30AC63"/>
                                    <path d="M25.5001 11.3662L13.2501 23.6162L8 18.3662" stroke="white" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                            </span>
                            <span className="stepTitle">One-Click In-Context Learning <br /> <span>(or select Hyperparameters)</span></span>
                        </div>
                        <div className="stepItem">
                            <span className="circle">
                                <svg xmlns="http://www.w3.org/2000/svg" width="33" height="34" viewBox="0 0 33 34" fill="none">
                                    <circle cx="16.5" cy="16.5" r="16.5" transform="matrix(1 0 0 -1 0 33.3662)" fill="#30AC63"/>
                                    <path d="M25.5001 11.3662L13.2501 23.6162L8 18.3662" stroke="white" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                            </span>
                            <span className="stepTitle performance-val">Evaluate performance, <br /> ready-to-use AI Co-Pilot</span>
                        </div>
                    </div>
                        {/* <div className='check_video_btn'>
                            <button type='button' >Check Video</button>
                        </div> */}
                </div>
            </div>
        </div>
        </>
        
    )
}

export default LandingSec3