import logo from '../assets/svgs/logo.svg'; 

const Header = ({title, subtitle , disclaimer }) => {
    return <>

      { disclaimer && 

       <div className="no-api-keys-sticky-warning-message text-center">
            <div className="alert alert-warning p-2" role="alert" style={{ marginBottom: 0 }}>
                {/* <img width="20px" className="mb-1" src={warningIcon} alt="" /> */}
                <span className="ml-2">
                <strong style={{ textDecoration: "underline" }}>Disclaimer:</strong> :   
                   This fine-tuning is provided as an illustrative example demonstrating how Texti enables code-free fine-tuning.        
                </span>
            </div>
        </div> 
      }
           
      <div className='main-header' >
      <div className="container">
        <div className='header-wrapper'>
          <div className='logo-img'>
            <img style={{height:"42px"}} src={logo} className='img-fluid' alt="img" />
          </div>
          {
            title && subtitle && 
            <div className="">
                <h4 className='titel'>{title}</h4>
                <p className='description'>{subtitle}</p>
            </div>
          }
          <a href="https://app.texti.ai">

            <div className='d-flex align-items-center header-buttons'>
              {/* <div className='login me-4'>Log In</div>  */}
              <button style={{ color:'#FFFFFF'}}> Start Fine-tuning - It's Free! </button>

            </div>

          </a>
        </div>
      </div>
    </div>
    </>
}


export default Header ; 
