const LandingSec7 = () => {
    return (
        <div className="section7_main container">
            <div className="section7_had">
                <div></div>
                <p className="m-0">FAQ’s</p>
            </div>

            <div classNameName="sec7_accordion">
                <div className="accordion accordion-flush" id="accordionFlushExample">
                    {/* <div className="accordion-item">
                        <h2 className="accordion-header" id="flush-headingOne">
                            <button className="accordion-button collapsed" type="button" data-toggle="collapse" data-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                            Can you cover end-to-end software delivery?
                            </button>
                        </h2>
                        <div id="flush-collapseOne" className="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-parent="#accordionFlushExample">
                            <div className="accordion-body">Placeholder content for this accordion, which is intended to demonstrate the <code>.accordion-flush</code> className. This is the first item's accordion body.</div>
                        </div>
                    </div> */}
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="flush-headingTwo">
                            <button className="accordion-button collapsed" type="button" data-toggle="collapse" data-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
                                Do I need data science knowledge to use Texti.ai?
                            </button>
                        </h2>
                        <div id="flush-collapseTwo" className="accordion-collapse collapse" aria-labelledby="flush-headingTwo" data-parent="#accordionFlushExample">
                            <div className="accordion-body">
                            No, you don't need data science or coding knowledge to use Texti.ai. It's designed for anyone who fears losing their job to AI or is eager to become a super user of foundational AI models.
                            Moreover, we try our best to keep our <a style={{color: '#795CB2'}} href="https://docs.texti.ai/" target="_blank" rel="noreferrer">documentation</a> simple, and are always here to assist you if needed. 
                            </div>
                        </div>
                    </div>
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="flush-headingThree">
                            <button className="accordion-button collapsed" type="button" data-toggle="collapse" data-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
                            How much does it cost?
                            </button>
                        </h2>
                        <div id="flush-collapseThree" className="accordion-collapse collapse" aria-labelledby="flush-headingThree" data-parent="#accordionFlushExample">
                            <div className="accordion-body">
                            Currently, our platform is available for free, with a monthly limit of 5 fine-tunes and in-context learning (AI Co-Pilots). 
                            In-Context learning is entirely free, but for fine-tuning, you will be charged based on the number of tokens, following the pricing set by foundational model providers like OpenAI
                            </div>
                        </div>
                    </div>
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="flush-headingOne">
                            <button className="accordion-button collapsed" type="button" data-toggle="collapse" data-target="#flush-collapseOne1" aria-expanded="false" aria-controls="flush-collapseOne1">
                             Which foundational AI models are available ? 
                            </button>
                        </h2>
                        <div id="flush-collapseOne1" className="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-parent="#accordionFlushExample">
                            <div className="accordion-body">
                                Currently, we offer full no-code fine-tuning for Davinci-002 and Babbage-002, with limited availability for LlaMA-65B and BERT. 
                                For in-context learning, we support GPT-3.5-turbo, GPT-4, and Llama-65B. We anticipate providing full support for many open-source models by early next year.
                            </div>
                        </div>
                    </div>
                    {/* <div className="accordion-item">
                        <h2 className="accordion-header" id="flush-headingOne">
                            <button className="accordion-button collapsed" type="button" data-toggle="collapse" data-target="#flush-collapseOne2" aria-expanded="false" aria-controls="flush-collapseOne2">
                            What is your preferred development methodology?
                            </button>
                        </h2>
                        <div id="flush-collapseOne2" className="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-parent="#accordionFlushExample">
                            <div className="accordion-body">Placeholder content for this accordion, which is intended to demonstrate the <code>.accordion-flush</code> className. This is the first item's accordion body.</div>
                        </div>
                    </div> */}
                    {/* <div className="accordion-item">
                        <h2 className="accordion-header" id="flush-headingOne">
                            <button className="accordion-button collapsed" type="button" data-toggle="collapse" data-target="#flush-collapseOne3" aria-expanded="false" aria-controls="flush-collapseOne3">
                            How do you control the quality of the software you deliver?
                            </button>
                        </h2>
                        <div id="flush-collapseOne3" className="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-parent="#accordionFlushExample">
                            <div className="accordion-body">Placeholder content for this accordion, which is intended to demonstrate the <code>.accordion-flush</code> className. This is the first item's accordion body.</div>
                        </div>
                    </div> */}
                </div>
            </div>
        </div>
    )
}

export default LandingSec7