import logo from '../assets/svgs/logo.svg';

const Footer = () => {
  return <>
    <div className='footer-wrapper'>
      <div className="footer-wrapper-main">
        <div className='footer-top'>
          <div className="row m-0">
            <div className="col-10 col-lg-7">
              <div className='footer-about' style={{ backgroundColor: "#F7FAFC" }}>
                <img src={logo} style={{ height: "40px" }} className='img-fluid' alt="img" />
                {/* <p>Texti.ai is world's firt no code fine-tuning tool</p> */}
              </div>
            </div>
            <div className="col-lg-3 d-none d-lg-block col-md-4 col-sm-12">
              <div className="footer-location">
                <h2>Company</h2>
                <p style={{ color: "#0070E0" }}>
                  <a href="/privacy"> Privacy Policy </a>
                </p>
                <p style={{ color: "#0070E0" }}>
                  <a href="/terms"> Terms of Services </a>
                </p>
              </div>
            </div>
            <div className="col-2">
              <div className="footer-helpcenter">
                <h2>Contact</h2>
                <div className='footer-help-center-head'>
                  <p> hello@texti.ai </p>
                </div>
                {/* <div className='three-svgs'>
                  <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
                    <path d="M22.8652 12.3672C22.8652 6.84719 18.3852 2.36719 12.8652 2.36719C7.34523 2.36719 2.86523 6.84719 2.86523 12.3672C2.86523 17.2072 6.30523 21.2372 10.8652 22.1672V15.3672H8.86523V12.3672H10.8652V9.86719C10.8652 7.93719 12.4352 6.36719 14.3652 6.36719H16.8652V9.36719H14.8652C14.3152 9.36719 13.8652 9.81719 13.8652 10.3672V12.3672H16.8652V15.3672H13.8652V22.3172C18.9152 21.8172 22.8652 17.5572 22.8652 12.3672Z" fill="black" />
                  </svg>

                  <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 19 19" fill="none">
                    <path d="M16.8652 0.367188C17.3957 0.367188 17.9044 0.577901 18.2794 0.952974C18.6545 1.32805 18.8652 1.83675 18.8652 2.36719V16.3672C18.8652 16.8976 18.6545 17.4063 18.2794 17.7814C17.9044 18.1565 17.3957 18.3672 16.8652 18.3672H2.86523C2.3348 18.3672 1.82609 18.1565 1.45102 17.7814C1.07595 17.4063 0.865234 16.8976 0.865234 16.3672V2.36719C0.865234 1.83675 1.07595 1.32805 1.45102 0.952974C1.82609 0.577901 2.3348 0.367188 2.86523 0.367188H16.8652ZM16.3652 15.8672V10.5672C16.3652 9.70258 16.0218 8.87339 15.4104 8.26202C14.799 7.65065 13.9698 7.30719 13.1052 7.30719C12.2552 7.30719 11.2652 7.82719 10.7852 8.60719V7.49719H7.99523V15.8672H10.7852V10.9372C10.7852 10.1672 11.4052 9.53719 12.1752 9.53719C12.5465 9.53719 12.9026 9.68469 13.1652 9.94724C13.4277 10.2098 13.5752 10.5659 13.5752 10.9372V15.8672H16.3652ZM4.74523 5.92719C5.1908 5.92719 5.61811 5.75019 5.93317 5.43513C6.24824 5.12007 6.42523 4.69275 6.42523 4.24719C6.42523 3.31719 5.67523 2.55719 4.74523 2.55719C4.29702 2.55719 3.86716 2.73524 3.55022 3.05218C3.23329 3.36911 3.05523 3.79897 3.05523 4.24719C3.05523 5.17719 3.81523 5.92719 4.74523 5.92719ZM6.13523 15.8672V7.49719H3.36523V15.8672H6.13523Z" fill="black" />
                  </svg>

                  <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
                    <path d="M8.66523 2.36719H17.0652C20.2652 2.36719 22.8652 4.96719 22.8652 8.16719V16.5672C22.8652 18.1054 22.2542 19.5807 21.1665 20.6684C20.0787 21.7561 18.6035 22.3672 17.0652 22.3672H8.66523C5.46523 22.3672 2.86523 19.7672 2.86523 16.5672V8.16719C2.86523 6.62893 3.4763 5.15368 4.56402 4.06597C5.65173 2.97826 7.12698 2.36719 8.66523 2.36719ZM8.46523 4.36719C7.51046 4.36719 6.59478 4.74647 5.91965 5.4216C5.24452 6.09673 4.86523 7.01241 4.86523 7.96719V16.7672C4.86523 18.7572 6.47523 20.3672 8.46523 20.3672H17.2652C18.22 20.3672 19.1357 19.9879 19.8108 19.3128C20.486 18.6376 20.8652 17.722 20.8652 16.7672V7.96719C20.8652 5.97719 19.2552 4.36719 17.2652 4.36719H8.46523ZM18.1152 5.86719C18.4468 5.86719 18.7647 5.99888 18.9991 6.2333C19.2335 6.46772 19.3652 6.78567 19.3652 7.11719C19.3652 7.44871 19.2335 7.76665 18.9991 8.00107C18.7647 8.23549 18.4468 8.36719 18.1152 8.36719C17.7837 8.36719 17.4658 8.23549 17.2313 8.00107C16.9969 7.76665 16.8652 7.44871 16.8652 7.11719C16.8652 6.78567 16.9969 6.46772 17.2313 6.2333C17.4658 5.99888 17.7837 5.86719 18.1152 5.86719ZM12.8652 7.36719C14.1913 7.36719 15.4631 7.89397 16.4008 8.83165C17.3385 9.76934 17.8652 11.0411 17.8652 12.3672C17.8652 13.6933 17.3385 14.965 16.4008 15.9027C15.4631 16.8404 14.1913 17.3672 12.8652 17.3672C11.5392 17.3672 10.2674 16.8404 9.3297 15.9027C8.39202 14.965 7.86523 13.6933 7.86523 12.3672C7.86523 11.0411 8.39202 9.76934 9.3297 8.83165C10.2674 7.89397 11.5392 7.36719 12.8652 7.36719ZM12.8652 9.36719C12.0696 9.36719 11.3065 9.68326 10.7439 10.2459C10.1813 10.8085 9.86523 11.5715 9.86523 12.3672C9.86523 13.1628 10.1813 13.9259 10.7439 14.4885C11.3065 15.0511 12.0696 15.3672 12.8652 15.3672C13.6609 15.3672 14.4239 15.0511 14.9866 14.4885C15.5492 13.9259 15.8652 13.1628 15.8652 12.3672C15.8652 11.5715 15.5492 10.8085 14.9866 10.2459C14.4239 9.68326 13.6609 9.36719 12.8652 9.36719Z" fill="black" />
                  </svg>
                </div> */}
              </div>
            </div>
          </div>
        </div>
        <div className="footer-bottom">
          <p >© Copyright 2024 TEXTI.AI PTE. LTD.</p>
        </div>
      </div>
    </div>
  </>
}


export default Footer; 
