import React from 'react'

const Policy = () => { 

  return (
    <div className="iframe-container">
  <iframe
    src={'https://www.iubenda.com/privacy-policy/83778023/full-legal'}
    allowFullScreen
    style={{ width: '100%', height: '100vh' }}
  ></iframe>
</div>

  
  )
}


export default Policy ;