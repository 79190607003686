import React, { useState, useEffect } from 'react';
import Header from "../Landing/header"
import Footer from "../Landing/footer"
import Classification from "./classification" ;
import Generation from "./generation"; 
import Finetunes from "./data/finetunes.json" ; 
import { useParams , Redirect } from "react-router-dom";


const Main = ({}) => {

    const item= useParams().item ;
    const data= Finetunes.filter(i => i.url_name === item)

    if (data.length == 0) return <Redirect to="/fine-tunes" />


    return <>
        <Header disclaimer={true} />
        { data[0].finetune.task==='text-classification' && <Classification Data={data} /> }
        { data[0].finetune.task==='text-generation' && <Generation Data={data} /> }      
        <Footer />
    </>
}


export default Main ; 
