const LandingSec4 = () =>{
    return(
        <div className="section4_main container-fluid">
            <div>
                <p>Become an AI expert without writing a single line of code – ride the new wave with Texti!</p>
                <button type="button" className="d-md-block d-none">
                    <a href="https://calendly.com/mukund-texti/30min" target="_blank" rel="noreferrer" > Get Started </a>
                </button>
            </div>
        </div>
    )
}

export default LandingSec4