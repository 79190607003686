
/*
import { Table } from 'rsuite';

const ResultTable = ({ data }) => {

    const columnNames = Object.keys(data[0]);
    console.log('columnNames', columnNames)


    return <Table
            className=""
            height={350}
            // width={500}
            data={data[0]}
            style={{
                fontSize: "12px",
                color: "#6b6d6d",
                cursor: "default",
                
            }}
            >
            {columnNames.map((columnName, index) => (
                <Table.Column key={columnName} resizable={index > 0} width={index === 0 ? 50 : undefined}>
                    <Table.HeaderCell className="ModalTableHeaderCell">{columnName}</Table.HeaderCell>
                    <Table.Cell dataKey={columnName.toLowerCase().replace(/\s+/g, '_')} />
                </Table.Column>
            ))}
            </Table>
}

export default ResultTable; 
*/ 


const ResultTable = ({ data }) => {


    return <>
        <div className="table-responsive" >
            <table className="table" style={{width:'20% !important'}} >
                <thead>
                    <tr>
                        <th scope="col">Step</th>
                        <th scope="col">Elapsed Tokens</th>
                        <th scope="col">Elapsed Examples</th>
                        <th scope="col">Training Loss</th>
                        <th scope="col">Training Sequence Accurancy</th>
                        <th scope="col">Training Token Accuracy</th>
                        <th scope="col">Validation Loss</th>
                        <th scope="col">Validation Sequence Accuracy</th>
                        <th scope="col">Validation Token Accuracy</th>
                        <th scope="col">Classification Aaccuracy</th>
                        <th scope="col">Classification Weightd score</th>
                    </tr>
                </thead>
                <tbody style={{width:'20% !important'}}>
                    {
                        data?.map((item, i) => (
                            <tr key={i}>
                                <td>{item.step}</td>
                                <td>{item.elapsed_tokens}</td>
                                <td>{item.elapsed_examples}</td>
                                <td>{item.training_loss}</td>
                                <td>{item.training_sequence_accuracy}</td>
                                <td>{item.training_token_accuracy}</td>
                                <td>{item.validation_loss}</td>
                                <td>{item.validation_sequence_accuracy}</td>
                                <td>{item.validation_token_accuracy}</td>
                                <td>{item['classification/accuracy']}</td>
                                <td>{item['classification/weighted_f1_score']}</td>
                            </tr>
                        ))
                    }
                </tbody>
            </table>
        </div> 
    </>
}


export default ResultTable; 
