const LandingSec5 = () =>{
    return(
        <div className="section5_main container">
            <div className="section5_hed">
                <div></div>
                <p>Why Customize foundational AI Models?</p>
            </div>
            <div className="row m-0">
                <div className="col-12 col-md-6 col-lg-4  pt-lg-0 pt-5 mt-lg-0 mt-4 Customize_class Customize_class">
                    <p className="mb-2">Tailored <br /> Solutions</p>
                    <span>Customization provides precise solutions, directly addressing a domain-specific needs. Tailoring responses can offer a more personalized, brand-aware experiences to your customers</span>
                </div>
                <div className="col-12 col-md-6 col-lg-4 pt-lg-0 pt-5 mt-lg-0 mt-4 Customize_class">
                    <p className="mb-2">AI-Driven <br /> Automations</p>
                    <span>Customized AI models can be orchestrated to function together as AI agents, improving efficiency and enhancing automation capabilities across a range of tasks.</span>
                </div>
                <div className="col-12 col-md-6 col-lg-4 pt-lg-0 pt-5 mt-lg-0 mt-4 Customize_class">
                    <p className="mb-2">Enhanced <br /> Performance</p>
                    <span>Through fine-tuning and in-context learning, models perform better on domain-specific tasks and can seamlessly integrate into business processes.</span>
                </div>
                <div className="col-12 col-md-6 col-lg-4 pt-5 mt-4 Customize_class">
                    <p className="mb-2">Cost <br /> Efficiency</p>
                    <span>Customized models, designed to efficiently handle specific tasks offer significant cost benefits, boost employee morale, and get more work done</span>
                </div>
                <div className="col-12 col-md-6 col-lg-4 pt-5 mt-4 Customize_class" >
                    <p className="mb-2">AI Governance & <br /> Bias Mitigations</p>
                    <span>Collaborative customization of AI models enhances transparency in the model training process and helps reduce bias, addressing the 'black box' issue prevalent in AI-specific data science development.</span>
                </div>
                <div className="col-12 col-md-6 col-lg-4 pt-5 mt-4 Customize_class">
                    <p className="mb-2">Data Privacy & <br /> Compliance</p>
                    <span>Customizing models allows businesses to maintain control over their data, ensuring better compliance with privacy regulations</span>
                </div>
            </div>
        </div>
    )
}

export default LandingSec5