import Image1 from './Images/section6 Image1.svg'
import Image2 from './Images/section6 Image2.svg'
import Image3 from './Images/section6 Image3.svg'
import Image4 from './Images/section6 Image4.svg'
import Image5 from './Images/section6 Image5.svg'

const LandingSec6 = () =>{
    return(
        <div className="section6_main container">
            <div className="section6_had">
                <div></div>
                <p>Industry Examples</p>
            </div>

            <div className="row m-0 Industry-all-images">
                <div className="col-lg-6 col-12  position-relative item">
                    <h2 className='industryTitle'>Human Resources</h2>
                    <img src={Image1} alt="" />
                </div>
                <div className="col-lg-3 col-12  position-relative item">
                    <h2 className='industryTitle'>Engineering</h2>
                    <img src={Image2} alt="" />
                </div>
                <div className="col-lg-3 col-12  position-relative item">
                    <h2 className='industryTitle'>Healthcare</h2>
                    <img src={Image3} alt="" />
                </div>
                <div className="col-lg-4 col-12  position-relative item">
                    <h2 className='industryTitle'>Legal</h2>
                    <img src={Image4} alt="" />
                </div>
                <div className="col-lg-8 col-12  position-relative item">
                    <h2 className='industryTitle'>Investing & Finance</h2>
                    <img src={Image5} alt="" />
                </div>
            </div>
        </div>
    )
}

export default LandingSec6