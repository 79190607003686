import Header from './header';
import gropImg1 from './Images/Group 3182.svg'
import gropImg2 from './Images/Group 3183.svg'
import ExternalIcon from "../assets/svgs/externalLink.png"
import Ftsettings from "../assets/svgs/ft-settings.svg"

const LandingSlider = () => {
    return (
        <div className="bannerSection">
            <div className="row m-0">
                <div className="col-md-6 col-12 p-0">
                    <Header/>
                    <div className='Landing_main_div'>
                        <div className='main_header'>
                            <p style={{fontWeight:600}} >Customize <span>Generative AI</span>, </p>
                            <p style={{fontWeight:600}} > no code required!</p>
                        </div>
                        <div class='main_paragraph mt-2'>
                            <p>Texti is your gateway to the power of foundational AI models.<br/>
                            With its no-code-first perspective, it ensures that everyone, irrespective of their technical expertise, can harness the full potential of AI.</p>
                            <p class="announcement"><a href="/ai-agent" target='_blank' > Texti Launches AI Agent for Enterprises  <img src={ExternalIcon} alt="" style={{ height:'14px' , width:'14px'}}/> </a></p>
                        </div>

                        <div className='btn_group mt-4'>
                            {/* <button type='button' className='btn_login'>
                                <a href="https://app.texti.ai/login" target='_blank' rel="noreferrer" >Log In</a>
                            </button> */}
                            {/* <button type='button' className='btn_sign_up'>
                                <a href="https://app.texti.ai/signup" target='_blank' rel="noreferrer" >Start for Free</a>                                
                            </button> */}
                            <button type='button' className='btn_sign_up'>
                                <a href="https://calendly.com/mukund-texti/30min" target='_blank' rel="noreferrer" >Let's Talk</a>                                
                            </button>
                        </div>
                    </div>
                </div>
        <div id="carouselExampleIndicators" className="carousel slide col-md-6 col-12" data-ride="carousel">
            <div className="carousel-indicators">
                <button type="button" data-target="#carouselExampleIndicators" data-slide-to="0" className="active slider-bottom-button" aria-current="true" aria-label="Slide 1"></button>
                <button type="button" data-target="#carouselExampleIndicators" data-slide-to="1" aria-label="Slide 2" className='slider-bottom-button'></button>
            </div>
            <div className="carousel-inner">
                <div className="carousel-item active">
                    <img src={gropImg1} className="d-block w-10" alt="..." />
                    {/* <img src={Ftsettings} className="d-block w-100" alt="..." /> */}
                </div>
                <div className="carousel-item">
                    <img src={gropImg2} className="d-block w-100" alt="..." />
                </div>
            </div>
            <button className="carousel-control-prev" type="button" data-target="#carouselExampleIndicators" data-slide="prev">
                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Previous</span>
            </button>
            <button className="carousel-control-next" type="button" data-target="#carouselExampleIndicators" data-slide="next">
                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Next</span>
            </button>
        </div>
        </div>
        </div>
    )
}

export default LandingSlider