import React from 'react';
import logo from '../assets/svgs/logo.svg'; 

const BlogInProgress = () => {
  return (
    <>  
        
        <div className='main-header'>
      <div className="container">
        <div className='header-wrapper'>
          <div className='logo-img'>
            <img src={logo} className='img-fluid' alt="img" />
          </div>
          <div className='d-flex align-items-center header-buttons'>
            {/* <div className='login me-4'>Log In</div> */}
            {/* <button>Add To Chrome</button> */}
             <a className="blog-link-temp" href="/">Home</a>
          </div>
        </div>
      </div>
    </div>
        <div className="blog-in-progress mt-5">
        <p>This blog page is a work in progress. Please check back later for updates!</p>
        </div>
    
    </>
  );
};

export default BlogInProgress;
