import React, { useState } from 'react';
import './LandingPage.css'
import LandingSlider from './LandingSlider'
import LandingSec1 from './LandingSec1'
import LandingSec2 from './LandingSec2'
import LandingSec3 from './LandingSec3'
import LandingSec4 from './LandingSec4'
import LandingSec5 from './LandingSec5'
import LandingSec6 from './LandingSec6'
import LandingSec7 from './LandingSec7'
import Footer from './footer'


const LandingPage = () => {
  return (
    <div className='NewLandingPage'>
        <LandingSlider/>
        <LandingSec1 />
        <LandingSec2 />
        <LandingSec3 />
        <LandingSec4 />
        <LandingSec5 />
        <LandingSec6 />
        <LandingSec7/>
        <Footer />
    </div>
  )
}

export default LandingPage