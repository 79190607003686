import React, { useState, useEffect } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';

const LossChart = ({ data }) => {
  const [trainLoss, setTrainLoss] = useState([]);
  const [valLoss, setValLoss] = useState([]);

  useEffect(() => {

    const trainValues = data.map((d) => ({
      step: Number(d.step),
      training_loss: Number(d.training_loss)
    }));

    const valValues = data.filter((d) => d.validation_loss !== '').map((d) => ({
      step: Number(d.step),
      validation_loss: Number(d.validation_loss)
    }));

    const chartData = trainValues.map((d, i) => ({
      step: d.step,
      training_loss: d.training_loss,
      validation_loss: (valValues[i] && valValues[i].validation_loss) || null
    }));

    setTrainLoss(chartData);
    setValLoss(valValues);
  }, []);

  return (
    <>
      {
        <LineChart width={1000} height={350} style={{fontSize:'12px'}} data={trainLoss}>
          <XAxis dataKey="step" orientation='bottom' label={{ value: "Step", dy:13,  position: "center"}} />
          <YAxis label={{ value: "Loss", angle: -90, position: "insideLeft", offset: 0 }} />
          <CartesianGrid stroke="#D7E1E8" strokeDasharray="1 1" />
          <Tooltip />
          <Legend verticalAlign="top" />
          <Line type="monotone" dataKey="training_loss" strokeWidth={2} stroke="rgb(75, 192, 192)" fill='rgb(75, 192, 192)' />
          <Line type="monotone" dataKey="validation_loss" strokeWidth={2} stroke="rgb(192, 75, 192)" fill='rgb(192, 75, 192)' />
        </LineChart>
      }
    </>
  );
};



export default LossChart;
