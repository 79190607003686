// import allmodels from './Images/allmodels.png'
import allmodels from './Images/allModels.svg'

const LandingSec2 = () => {
    return (
        <div className="section2_main_div container">
            <div className="section2_header">
                <div></div>
                <p className='m-0'>Texti’s features at glance</p>
            </div>

            <div className="tab-bar-div" style={{ marginTop: '80px'}}>
                <div className="d-flex align-items-start flex-wrap">
                    <div className="nav postion-change-div flex-column nav-pills me-3" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                        <button className="nav-link active" id="v-pills-home-tab" data-toggle="pill" data-target="#v-pills-home" type="button" role="tab" aria-controls="v-pills-home" aria-selected="true">All Models, One Place</button>
                        <button className="nav-link" id="v-pills-profile-tab" data-toggle="pill" data-target="#v-pills-profile" type="button" role="tab" aria-controls="v-pills-profile" aria-selected="false">Collaborative Labelling</button>
                        <button className="nav-link" id="v-pills-messages-tab" data-toggle="pill" data-target="#v-pills-messages" type="button" role="tab" aria-controls="v-pills-messages" aria-selected="false">AI & Data Governance</button>
                        <button className="nav-link" id="v-pills-settings-tab" data-toggle="pill" data-target="#v-pills-settings" type="button" role="tab" aria-controls="v-pills-settings" aria-selected="false">Explainability & Evaluation</button>
                        <button className="nav-link" id="v-pills-easy-tab" data-toggle="pill" data-target="#v-pills-easy" type="button" role="tab" aria-controls="v-pills-easy" aria-selected="false">Easy-to-Use Data Connectors</button>
                    </div>
                    <div className="tab-content" id="v-pills-tabContent">
                        <div className="tab-pane fade show active" id="v-pills-home" role="tabpanel" aria-labelledby="v-pills-home-tab">
                            <div className='all-models-main mt-4'>

                                <p className='allmodel-hed'>All Models, One Place</p>
                                <img src={allmodels} alt="" className='allmodel-img' />
                                <p className='allmodels-p mt-2'>
                                    Texti serves as a unified platform for customizing both proprietary and open-source foundational AI models. 
                                    It is agnostic to specific AI models, offering broad compatibility and flexibility.
                                </p>
                            </div>
                        </div>
                        <div className="tab-pane fade" id="v-pills-profile" role="tabpanel" aria-labelledby="v-pills-profile-tab">
                            <div className='all-models-main mt-4'>
                            <p className='allmodel-hed'>Collaborative Labelling </p>
                                {/* <img src={allmodels} alt="" className='allmodel-img' /> */}
                                <p className='allmodels-p mt-2'>
                                    Texti streamlines collaborative labelling. <br/>
                                    It employs majority voting for class selection, balances datasets for text-classification tasks, and allows shared editing of labeled instances for text-generation tasks.
                                </p>
                            </div>
                        
                        </div>
                        <div className="tab-pane fade" id="v-pills-messages" role="tabpanel" aria-labelledby="v-pills-messages-tab">

                            <div className='all-models-main mt-4'>
                            <p className='allmodel-hed'> AI & Data Governance </p>
                                {/* <img src={allmodels} alt="" className='allmodel-img' /> */}
                                <p className='allmodels-p mt-2'>
                                Ensuring all stakeholders have transparency into the data used for customizing AI models is pivotal. 
                                This transparency not only aids in identifying and eliminating potential biases but also establishes robust governance in AI and data management.
                                </p>
                            </div>

                        
                        </div>
                        <div className="tab-pane fade" id="v-pills-settings" role="tabpanel" aria-labelledby="v-pills-settings-tab">
                                  
                            <div className='all-models-main mt-4'>
                                    <p className='allmodel-hed'> Explainability & Evaluation </p>
                                    {/* <img src={allmodels} alt="" className='allmodel-img' /> */}
                                    <p className='allmodels-p mt-2'>
                                    Evaluating performance of different foundational AI models on identical tasks is crucial for a cost-effective selection of the most accurate model, one that's best adapted to the specific task and data. <br/>
                                    Texti facilitates this essential evaluation, ensuring optimal outcomes
                                    </p>
                            </div>
                            
                        </div>
                        <div className="tab-pane fade" id="v-pills-easy" role="tabpanel" aria-labelledby="v-pills-easy-tab">

                            <div className='all-models-main mt-4'>
                                    <p className='allmodel-hed'> Easy-to-Use Data Connectors </p>
                                    {/* <img src={allmodels} alt="" className='allmodel-img' /> */}
                                    <p className='allmodels-p mt-2'>
                                    With Texti, users have the flexibility to bring their data seamlessly. For fine-tuning, they can utilize SQL, Google Sheets, or CSV. <br/>
                                    And for in-context learning, unstructured data in PDF or .txt formats is readily supported
                                    </p>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default LandingSec2