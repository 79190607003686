import React from 'react'

const Terms = () => { 

  return (
    <div className="iframe-container">
  <iframe
    src={'https://www.iubenda.com/terms-and-conditions/83778023'}
    allowFullScreen
    style={{ width: '100%', height: '100vh' }}
  ></iframe>
</div>


  )
}


export default Terms ;